import React from 'react';
import './Instagram.css';

const Instagram = () => {

  return (
    <div className='instagram-content'>
      <span className='instagram'/>
      {/* "" */}
      <a href="https://www.instagram.com/explore/tags/kempec_family/"> Insta</a>
    </div>
  )
};

export default Instagram;